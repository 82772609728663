html {
  font-family: -apple-system, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  box-sizing: border-box;
  font-size: 12px;
}

@media (min-width: 720px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 16px;
  }
}

a,
div[role='menuitem'] {
  cursor: pointer;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

a,
a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

pre {
  font-family: monospace;
}

input,
textarea {
  border: none;
  border-color: none;
  border-style: none;
  border-width: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  font: inherit;
  cursor: pointer;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-transform: inherit;
  text-indent: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: unset;
}

button:focus-visible,
a:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2.5px rgba(21, 156, 228, 0.4);
}

input,
textarea {
  border: none;
  border-color: none;
  border-style: none;
  border-width: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  font: inherit;
}

a,
div[role='menuitem'] {
  cursor: pointer;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

a:visited {
  text-decoration: inherit;
  color: inherit;
}

body {
  padding: 0;
  margin: 0;
  line-height: 1.62rem;
  font-size: 1rem;
  overscroll-behavior: none;
}

input,
textarea {
  border: none;
  border-color: none;
  border-style: none;
  border-width: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  font: inherit;
  cursor: pointer;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-transform: inherit;
  text-indent: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

button:focus-visible,
a:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2.5px rgba(21, 156, 228, 0.4);
}

input,
textarea {
  border: none;
  border-color: none;
  border-style: none;
  border-width: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  font: inherit;
}
/**
* RESET CSS
*/

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  vertical-align: baseline;
  font: inherit;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* @media (min-width: 1000px) {
  font-size: 15px;
}
@media (min-width: 1200px) {
  font-size: 16px;
}
@media (min-width: 1400px) {
  font-size: 16.2px;
}
@media (min-width: 1600px) {
  font-size: 16.5px;
}
@media (min-width: 2000px) {
  font-size: 17px;
}
@media (min-width: 3000px) {
  font-size: 18px;
}
@media (min-width: 4000px) {
  font-size: 20px;
} */

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #f3f3f8;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #f3f3f8, 0 0 5px #f3f3f8;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #f3f3f8;
  border-left-color: #f3f3f8;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 600;
}

strong,
.boldest {
  font-weight: 800;
}

h1 {
  font-size: 2rem;
}

.bound-to-max {
  max-width: 2048px;
  margin: 0 auto;
  width: 100%;
}

.border-none {
  border: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.pad-1-x {
  padding: 0 1rem;
}

.no-pointer-events {
  pointer-events: none;
}

/* 
 * We hide the default buttons that the atlassian issue collectors render,
 * so we can trigger them from a nice-looking help menu that we render instead.
 */
#atlwdg-trigger {
  display: none;
}

ul.with-bullets > li {
  list-style: inside;
}

.flex-right-container, .flex-left-container {
  display: flex;
  min-width: 0;
  box-sizing: border-box;
  align-items: center;
}

.flex-right-container > div, .flex-left-container > div {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
}

.flex-left-container > div:first-child {
  min-width: 0;
  flex: 1;
}

.flex-right-container > div:last-child {
  min-width: 0;
  flex: 1;
}

.modelcard-header {
  color: #195aff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.modelcard-description {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.modelcard-footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.hide-less-900 {
  border: pink 3px solid;
  @media (min-width: 900px) {
    display: none !important;
    border: blue 3px solid !important;
  }
}

/* 
 * We set the appropriate list-style for lists inside of markdown preview & editor
 */
.wmde-markdown>ol {
  list-style: decimal;
}
.wmde-markdown>ul {
  list-style: disc;
}